import './App.css';
import './AboutUs.css';

import React, { useState, useEffect } from 'react';
import {useRoutes, A, navigate} from 'hookrouter';
import axios from 'axios';



function App() {
  const routes = {
    '/' : () => <Home />,
    '/why': () => <Why />,
    '/pricing': () => <Pricing />,
    '/about' : () => <AboutUs />,
    '/disclaimer' : () => <Disclaimer />,
    '/partner/:id': ({id}) => <Home id={id} />,
  };

  const routeResults = useRoutes(routes);

  return (
    <div className="App">
        {routeResults}
    </div>
  );
}

function Home(props) {
  const [isSearching, setIsSearching] = useState(false);
  const [productType, setProductType] = useState('Flower');
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [thankYou, setThankYou] = useState(false);
  const [thankYouPartner, setThankYouPartner] = useState(false);

  const [deals, setDeals] = useState([])

  const [modal, setModal] = useState(false);
  const [partnerModal, setPartnerModal] = useState(false)

  
  const getDeals = async () => {
    try {
      const response = await axios.post('https://justuspenck.builtwithdark.com/daily_deals', {
        query: searchTerm,
        category: productType
    })
      // Handle the response data
      setDeals(response.data)
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    getDeals()
  }, []);
  

  const handleSearch = async () => {
    setIsSearching(true);
    setCategory('search')

    try {
      const response = await axios.post('https://justuspenck.builtwithdark.com/kbis_pass_test', {
        query: searchTerm,
        category: productType
    })
      let data = [...response.data]
      if(productType == 'Flower') {
        data[1] = deals[1]
      }
      if(productType == 'Prerolls') {
        data[1] = deals[0]
      }
      setSearchResults(data)
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleCategoryChange = (event) => {
    setProductType(event.target.value);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const togglePartner = () => {
    setPartnerModal(true)
  }

  if(thankYou) {
    return <ThankYou setThankYou={setThankYou} />;
  }

  if(thankYouPartner) {
    return <ThankYouPartner setThankYou={setThankYouPartner} />;
  }

  return (
    <div className="App">
      <Header />

    <main>
      <div className="search-container">
        <div className="tagline">Your Virtual Budtender</div>
        <div className="partner-text">AI for your wellness</div>
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Ask anything..."
            className="search-bar"
            id="searchBar"
            value={searchTerm}
            onChange={handleInputChange}
          />
          <button
            className="search-button"
            id="searchButton"
            onClick={handleSearch}
            disabled={isSearching}
          >
            {isSearching ? 'Searching...' : 'Search'}
          </button>
        </div>
        <div className="category-dropdown">
          <select
            className="category-select"
            id="categorySelect"
            value={productType}
            onChange={handleCategoryChange}
          >
            <option value="Flower">Flower</option>
            <option value="Edibles">Edibles</option>
            <option value="Prerolls">Pre-rolls</option>
            <option value="Concentrates">Concentrates</option>
            <option value="Vape">Vape Pens</option>
            <option value="Topicals">Topicals</option>
            <option value="Tinctures">Tinctures</option>
            <option value="Beverages">Beverages</option>
            <option value="Capsules">Capsules</option>
          </select>
        </div>
      </div>

        <CategoryButtons category={category} setCategory={setCategory} />

        {isSearching ? <Spinner /> : <Results searchResults={searchResults} category={category} searchTerm={searchTerm} deals={deals} productType={productType} setModal={setModal} /> }
        
        <div class="partner-section" onClick={togglePartner}>
            <span class="partner-text">Brand Partners<br /> sign up here</span>
            <a href="#" class="partner-button">Add Your Dispensary</a>
        </div>
        <Sponsors />

        {modal ? <Modal setModal={setModal} setThankYou={setThankYou} /> : <></>}
        {partnerModal ? <PartnerModal setPartnerModal={setPartnerModal} setThankYouPartner={setThankYouPartner} /> : <></>}
        
    </main>
    <Footer />
  </div>
  );
}

function Header() {
  let handleClick = () => {
    window.location.href = window.location.href;
  }
  return <header onClick={handleClick} class="video-container">
  <video autoPlay loop muted playsInline>
      <source src="/kannabis_logo.mp4" type="video/mp4" />
      Your browser does not support the video tag.
  </video>
</header>
}

function CategoryButtons(props) {
  let clickNewReleases = () => {
    props.setCategory('newReleases')
  }

  let clickDailyDeals = () => {
    props.setCategory('dailyDeals')
  }

  let clickTopSellers = () => {
    props.setCategory('topSellers')
  }

  return <div class="category-buttons">
            <button class={"category-button" + (props.category=='topSellers' ? ' active' : '')} data-category="topSellers" onClick={clickTopSellers}>Featured</button>
        </div>
}

function Modal(props) {
  const [email, setEmail] = useState('');
  let changeEmail = (e) => {
    setEmail(e.target.value)
  }

  let handleExitClick = () => {
    props.setModal(false)
  }

  let handleEmailClick = async () => {
    try {
      const response = await axios.post('https://justuspenck.builtwithdark.com/emaildiscount', {
        email: email
      });

      props.setModal(false)
      props.setThankYou(true)
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return <div id="emailModal" class="modal">
  <div class="modal-content">
      <span class="close" onClick={handleExitClick}>&times;</span>
      <h2>Exclusive 10% Discount!</h2>
      <p>Sign up now to receive your personalized search results and get an exclusive 10% discount on your next purchase!</p>
      <input type="email" id="emailInput" placeholder="Enter your email address" required onChange={changeEmail} />
      <button id="submitEmail" onClick={handleEmailClick}>Get My Discount</button>
  </div>
</div>
}

function PartnerModal(props) {
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [email, setEmail] = useState('');
  let changeEmail = (e) => {
    setEmail(e.target.value)
  }
  let changeWebsite = (e) => {
    setWebsite(e.target.value)
  }
  let changeName = (e) => {
    setName(e.target.value)
  }

  let handleExitClick = () => {
    props.setPartnerModal(false)
  }

  let handleEmailClick = async () => {
    try {
      const response = await axios.post('https://justuspenck.builtwithdark.com/partner', {
        email: email,
        website: website,
        name: name,
      });

      props.setThankYouPartner(true)
      props.setPartnerModal(false)
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return <div id="emailModal" class="modal">
  <div class="modal-content">
      <span class="close" onClick={handleExitClick}>&times;</span>
      <h2>Add your Dispensary or Become a Brand Partner</h2>
      <p></p>
      <input type="name" id="nameInput" placeholder="Enter your name" required onChange={changeName} />
      <input type="website" id="websiteInput" placeholder="Enter your website" required onChange={changeWebsite} />
      <input type="email" id="emailInput" placeholder="Enter your email address" required onChange={changeEmail} />
      <button id="submitEmail" onClick={handleEmailClick}>Submit</button>
  </div>
</div>
}

function Sponsors() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideLink, setCurrentSlideLink] = useState('https://cookies.co/');

  const slides = [
    { src: '/cookies.png', alt: 'Cookies', link: 'https://cookies.co/' },
    { src: '/buzzd.png', alt: 'Buzz\'d', link: 'https://buzzd.co' },
    { src: '/dutchie.png', alt: 'Dutchie', link:'https://dutchie.com' },
    { src: '/jeeter.png', alt: 'Jeeter', link: 'https://jeeter.com' },
    { src: '/leaflink.png', alt: 'Leaflink', link: 'https://leaflink.com' },
    { src: '/ga.png', alt: 'General Admission', link: 'https://generaladmission.ca' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="sponsor-carousel">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`sponsor-slide ${index === currentSlide ? 'active' : ''}`}
        >
         <img src={slide.src} alt={slide.alt} />
        </div>
      ))}
    </div>
  );
}

function Spinner() {
  return <div class="loading" id="loading">
          <div class="loading-spinner"></div>
        </div>
}

function Results(props) {
  let searchStuff = []
  searchStuff.push(<p class="search-query">Based on your search for "<span id="userQuery">{props.searchTerm}</span>", below are our recommendations:</p>)
  searchStuff.push()

  let results = []
  if(props.category == 'search') {
    props.searchResults.forEach(searchResult => {
      if(searchResult == null) {
      } else {
        results.push(<Result item={searchResult} />)
      }
    })
    results.splice(1, 0,  <Discount setModal={props.setModal} />);
  
  }

  if(props.category == 'topSellers' && props.deals[0]) {
    results.push(<Result item={props.deals[0]} />)
    results.push(<Discount setModal={props.setModal} />)
    results.push(<Result item={props.deals[1]} />)
  }

  if(results.length == 0) return <div />

  return <div class="result-container" id="resultContainer">
    <div id="searchResults" class="category-results active">
        {props.category == 'search' ? searchStuff : <></>}
        
        <div class="result-explanation" id="llmReasoning">
            
        </div>
        <div class="result-products" id="searchResultProducts">
          {results}
        </div>
          
    </div>
  </div>
}

function Result(props) {
  return <div class="result-product">
  
  <div class="result-product-info">
      <h3>{props.item.name}</h3>
      <p>{props.item.description}</p>
      <p>{props.item.Type}</p>
      <p>{props.item.THC || 'N/A'}</p>
      <p>{props.item.CBD || 'N/A'}</p>
      {/*<a href={props.item.link} class="product-link" target="_blank">View Product</a>*/}
  </div>
  <img src={props.item.image || ''} alt="${jsonData.name}" />
</div>
}

function Discount(props) {
  let handleClick = () => {
    props.setModal(true)
  }

  return <div class="result-product special-offer">
  <img src="./discount.png" alt="Special 10% Discount Offer" />
  <div class="result-product-info">
      <h3>Exclusive 10% Discount!</h3>
      <p>Sign up now to receive your search results to your Email and get a 10% discount on your purchase!</p>
      <a href="#" class="product-link" id="discountButton" onClick={handleClick}>Enter Email</a>
  </div>
</div>
}


function ThankYou(props) {
  let handleClick = () => {
    props.setThankYou(false)
  }

  return <div class='thank-you-container'>
  <div class="container">
          <h1>Thank You for Subscribing!</h1>
          <p>Your email <span id="userEmail"></span> has been successfully registered.</p>
          <p>Here's your exclusive 10% discount code:</p>
          <div class="discount-code">WELLNESS10</div>
          <p>Use this code at checkout to receive your discount on your first purchase.</p>
          <p>We've sent a copy of your personalized search results and this discount code to your email.</p>
          <a onClick={handleClick} class="back-link">Back to Homepage</a>
        </div>
        </div>
}

function ThankYouPartner(props) {
  let handleClick = () => {
    props.setThankYou(false)
  }

  return <div class='thank-you-container'>
  <div class="container">
          <h1>Thank You for Contacting us!</h1>
          <p></p>
          <p>We will be in touch soon</p>
          <a onClick={handleClick} class="back-link">Back to Homepage</a>
        </div>
        </div>
}

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <div className="footer-links">
          <A href='/disclaimer'><div className="footer-about-link">Disclaimer</div></A>
          <A href='/about'><div className="footer-about-link" >About Us</div></A>
          <A href='/pricing'><div className="footer-about-link">Pricing</div></A>
          <A href='/why'><div className="footer-about-link">Why</div></A>
        </div>
        <span className="footer-copyright">© {new Date().getFullYear()} Hygh Technology Innovation Corporation.</span>
        <span className="footer-copyright">All Rights Reserved.</span>
        <span className="footer-copyright">Contact us: hello@kannab.is</span>
      </div>
    </footer>
  );
};

const AboutUs = () => {
  return (
      <div className="container">
          <div className="header">
              <h1>About Us </h1>
              <p>Get to know more about our mission, vision, and values.</p>
          </div>

          <div className="content">
              <p>Our vision is to revolutionize your inquiries and provide accurate recommendations to improve your wellness. </p>

              <p>kannab.is was built by a team of serial entrepreneurs that were pioneers in fintech, blockchain, welltech, data engineering and AI preprocessing.</p>

              <p>Passionate about innovative technology, on a journey to make WELLNESS  inclusive for everyone.</p>
          </div>

          <footer>
          <a href="/" class="partner-button">Back To Search</a>
          </footer>
      </div>
  );
};

const Disclaimer = () => {
  return (
      <div className="container">
          <div className="header">
              <h1>Disclaimer</h1>
              <p>DISCLAIMER: THIS WEBSITE DOES NOT PROVIDE MEDICAL ADVICE.</p>
          </div>

          <div className="content">
          <p>
          The information, including but not limited to, text, graphics, images and other material contained on the kannab.is website are for informational purposes only. No material on this site is intended to be a substitute for professional medical advice, diagnosis or treatment. Always seek the advice of your physician or other qualified health care provider with any questions you may have regarding a medical condition or treatment and before undertaking a new health care regimen, and never disregard professional medical advice or delay in seeking it because of something you have read on this website.</p>
          </div>

          <footer>
          <a href="/" class="partner-button">Back To Search</a>
          </footer>
      </div>
  );
};

const Pricing = () => {
  return (
      <div className="container">
          <div className="header">
              <h1>Pricing</h1>
              <p></p>
          </div>

          <div className="content">
            <p>It's FREE!</p>

            <p>• add your dispensary </p>

            <p>• 1,000 product inquiries per month, per dispensary </p>

            <p>• Use our hyper-intelligent wellness engine to provide recommendtations to your Clients</p>

            <p>• Inquiries earn your Clients incentives, discounts and product promotions </p>

          <p></p>
            <p>kannab.is PRO available upon request hello@kannab.is!</p>

            <p>• unlimited product inquiries</p>
            <p>• product data intelligence</p>
            <p>• email conversion campaigns</p>
            <p>• CTA (Call To Action analytics)</p>
          </div>

          <footer>
          <a href="/" class="partner-button">Back To Search</a>
          </footer>
      </div>
  );
};

const Why = () => {
  return (
      <div className="container">
          <div className="header">
              <h1>Why</h1>
              <p>Why kannab.is</p>
          </div>

          <div className="content">
            <p>Business Intelligence: track your leads and inquiries, increase your conversions with incentives and recommendations</p>

<p>Exponentially more efficient and more insightful than  'human' Budtenders</p>

<p>Clients never need to wait for information and product recommendations</p>
          </div>

          <footer>
          <a href="/" class="partner-button">Back To Search</a>
          </footer>
      </div>
  );
};

export default App;